import type { IQueryParams, PaymentProperties } from '~/services/types';
import { useIQuery } from '~/services/useQuery';
import type { IPaymentRedirect, IPromocodeDetails, ISubscription, IUser } from '~/types/common';
import { useNuxtApp } from '#app';

export const getSubscriptions = (options?: IQueryParams) =>
  useIQuery<ISubscription[]>('v4/tarrifs/', { ...options, pagination: true });

export const buySubscriptionByCard = (payload: PaymentProperties) =>
  useNuxtApp().$apiFetch<IPaymentRedirect>('v4/payment/start/', { method: 'post', body: payload });

export const buySubscriptionByBalance = (payload: PaymentProperties) =>
  useNuxtApp().$apiFetch<IPaymentRedirect>('v4/balance/buy-subscription/', { method: 'post', body: payload });

export const checkPromocode = (promocode: string) =>
  useNuxtApp().$apiFetch<IPromocodeDetails>('v4/payment/promocode/', { params: { name: promocode }, method: 'get' });
